@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.App {
  background-color: black;
  font-family: "Outfit", sans-serif;
  color: #E8EAED;
}

h1 {
  font-size: 24px;
  margin: 0;
  margin-top: 20px;
}

h2 {
  font-size: 20px;
  margin: 30px 0 10px 0;
}

p {
  font-size: 16px;
  margin: 10px 0;
}

.content {
  padding: 20px;
}

a {
  color: #E8EAED;
  text-decoration: none;
}