.close-button {
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 20%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    transition: background-color 0.3s ease;
    z-index: 10;
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.close-button svg {
    width: 50px;
    height: 50px;
    fill: none;
}

.close-button-margin {
    height: 60px;
}

@media (min-width: 1024px) {
    .close-button {
        bottom: auto;
        top: 20px;
        left: 20px;
        transform: translateX(0);
    }
}