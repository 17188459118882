.event__banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 20px 0;
  }
  
  .event__logo {
    max-height: 60px;
    max-width: 50%;
  }
  
  .awards__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  
  .event__textbox {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }